const HaulageTitle = () => {
  return (
    <div className='pb-8 bottom-divider'>
      <h1 className='black-text-2 text-2xl'>Haulage</h1>
      <p className='black-text-4 text-sm font-light mt-1.5'>Please provide the details of the freight</p>
    </div>
  )
}

export default HaulageTitle
